export const StatusData = {
  STATUS_CONFIRM_MAIL: 0,
  STATUS_REGISTER_PROGRESS: 1,
  STATUS_READY_VERIFICATION: 2,
  STATUS_VERIFICATION_PROGRESS: 3,
  STATUS_UNCERTIFIED: 4,
  STATUS_CERTIFICATION_PROGRESS: 5,
  STATUS_CERTIFIED: 6,
  STATUS_DISABLED: 7
}

export const getStatusIcon = (status) => {
  if (status < StatusData.STATUS_CERTIFIED) {
    return { name: 'warning', color: 'orange' }
  } else {
    return { name: 'certified', color: 'green' }
  }
}
