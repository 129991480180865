import { ref, computed, markRaw } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'
import structuredClone from '@ungap/structured-clone'
import { useModalStore } from '@/store/modal'
import NbsWarningLeaving from '@/components/modals/NbsWarningLeaving.vue'

export const useLeaveFormModalStore = defineStore('useLeaveFormModalStore', () => {
  const router = useRouter()
  const changeRouteUrl = ref(null)
  const fields = ref({})
  const initialFields = ref({})
  const opened = ref(false)
  const submitted = ref(false)
  const editCount = ref(0)

  const isCompleting = computed(() => {
    return Object.entries(fields.value).reduce((acc, item) => {
      if (typeof item[1] === 'object' && item[1].value !== initialFields.value[item[0]].value) {
        acc++
      }
      return acc
    }, 0) > 0
  })

  const isEditing = computed(() => {
    return editCount.value > 0
  })

  const shouldOpenModal = computed(() => {
    return (isCompleting.value && !changeRouteUrl.value && !submitted.value) || (!changeRouteUrl.value && isEditing.value)
  })

  const initFields = (value) => {
    initialFields.value = value
    reset()
  }

  const reset = () => {
    opened.value = false
    editCount.value = 0
    setChangeRouteUrl(null)
    if (initialFields.value) {
      fields.value = structuredClone(initialFields.value)
    }
  }

  const setField = (key, { value, isValid }) => {
    if (fields.value[key]) {
      fields.value[key].value = value
      fields.value[key].isValid = isValid
    }
  }

  const setChangeRouteUrl = (value) => {
    changeRouteUrl.value = value
  }

  const handleOpenModal = ({ isSubmitted, to }) => {
    const modalStore = useModalStore()
    submitted.value = isSubmitted
    if (opened.value) return false
    if (shouldOpenModal.value) {
      setChangeRouteUrl(to)
      modalStore.open({
        component: markRaw(NbsWarningLeaving),
        closeButton: false,
        props: {
          leaveFunction: () => {
            reset()
            router.push(to)
          }
        },
        callbacks: {
          beforeClose: () => {
            setChangeRouteUrl(null)
            opened.value = false
          }
        }
      })
      opened.value = true
      return false
    } else {
      reset()
      modalStore.close()
      return true
    }
  }

  return {
    changeRouteUrl,
    fields,
    initialFields,
    opened,
    submitted,
    editCount,
    isCompleting,
    isEditing,
    shouldOpenModal,
    initFields,
    reset,
    setField,
    setChangeRouteUrl,
    handleOpenModal
  }
})
