export const HistoryData = {
  startingDate: 'Planification › Dates du projet',
  deliveryDate: 'Planification › Dates du projet',
  priority: 'Planification › Caractère prioritaire du projet',
  domain: 'Nom de domaine',
  alias: 'Nom de domaine › Alias',
  expirationDate: 'Nom de domaine › Date d\'expiration',
  domainOwner: 'Nom de domaine › Propriétaire',
  domainAdmin: 'Nom de domaine › Administrateur',
  serverSmntpType: 'Configuration SMTP › Serveur',
  recaptchaSiteKey: 'Paramètres ReCaptcha v3',
  recaptchaSecretKey: 'Paramètres ReCaptcha v3',
  defaultLang: 'Localisation des contenus'
}
