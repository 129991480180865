<script setup>
import { watch, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthentificationStore } from '@/store/authentification'
import { useProjectsStore } from '@/store/projects'
import { useCustomersStore } from '@/store/customers'
import { useUsersStore } from '@/store/users'
import NbsIcon from '@/components/NbsIcon.vue'
import NbsCard from '@/components/cards/NbsCard.vue'
import NbsMenu from '@/components/NbsMenu.vue'

const authentificationStore = useAuthentificationStore()
const projectsStore = useProjectsStore()
const customersStore = useCustomersStore()
const usersStore = useUsersStore()
const router = useRouter()
const isLoading = ref(false)

router.beforeEach(async (to) => {
  isLoading.value = true
  if (!authentificationStore.isLogged && !to.path.includes('login')) {
    return { name: 'login', query: { from: to.fullPath } }
  } else if (authentificationStore.isLogged && to.path.includes('login')) {
    return { path: '/' }
  } else {
    if (authentificationStore.isLogged) {
      customersStore.resetChecked()
      projectsStore.resetChecked()
      usersStore.resetChecked()
      if (customersStore.getListNumber === 0) {
        await customersStore.fetch()
      }
      if (projectsStore.getListNumber === 0) {
        await projectsStore.fetch()
      }
      if (usersStore.getListNumber === 0) {
        await usersStore.fetch()
      }
    }
  }
})

router.afterEach(() => {
  isLoading.value = false
})

watch(() => authentificationStore.token, (newValue) => {
  if (newValue === '') {
    router.push({ path: '/login' })
  }
})

watch(() => customersStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[2].itemsLength = newValue
})

watch(() => projectsStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[3].itemsLength = newValue
})

watch(() => usersStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[6].itemsLength = newValue
})

const NAV_ADMIN = ref([
  {
    icon: {
      name: 'dashboard'
    },
    label: 'Tableau de bord',
    url: '/'
  },
  {
    icon: {
      name: 'calendar-checked'
    },
    label: 'Planning',
    url: '/planning'
  },
  {
    icon: {
      name: 'customers'
    },
    label: 'Clients',
    url: '/customers',
    itemsLength: customersStore.getListNumber,
    notifAlert: true
  },
  {
    icon: {
      name: 'folder'
    },
    label: 'Projets',
    url: '/projects',
    itemsLength: projectsStore.getListNumber
  },
  {
    icon: {
      name: 'providers'
    },
    label: 'Prestataire tiers',
    url: '/providers',
    itemsLength: 0
  },
  {
    icon: {
      name: 'documents'
    },
    label: 'Documents',
    url: '/documents',
    itemsLength: 200
  },
  {
    icon: {
      name: 'community'
    },
    label: 'Communauté',
    url: '/community',
    itemsLength: usersStore.getListNumber,
    isGroup: true
  },
  {
    icon: {
      name: 'msg'
    },
    label: 'Communication',
    url: '/communication'
  },
  {
    icon: {
      name: 'catalog'
    },
    label: 'Catalogue produits',
    url: '/products',
    isGroup: true
  },
  {
    icon: {
      name: 'accounting'
    },
    label: 'Comptabilité',
    url: '/comptability'
  },
  {
    icon: {
      name: 'stats'
    },
    label: 'Statistique',
    url: '/stats'
  },
  {
    icon: {
      name: 'settings'
    },
    label: 'Paramètres',
    url: '/settings',
    isGroup: true
  },
  {
    icon: {
      name: 'support'
    },
    label: 'Support',
    url: '/support'
  },
  {
    icon: {
      name: 'help'
    },
    label: 'Aides et informations',
    url: '/help'
  }
])

const NAV_CONTACT = ref([
  {
    icon: {
      name: 'dashboard'
    },
    label: 'Tableau de bord',
    url: '/'
  }
])
</script>

<template>
  <div class="nbs-main" :class="{ 'nbs-main--login' : !authentificationStore.isLogged }">
    <header class="nbs-header" v-if="authentificationStore.isLogged">
      <nbs-icon icon="bs" class="nbs-header-logo"/>
      <button type="button" class="nbs-header-btn" @click="authentificationStore.disconnect">
        <img
          class="nbs-header-avatar" alt=""
          :src="authentificationStore.LogoFile.getFullPath ? authentificationStore.LogoFile.getFullPath : '@/assets/images/user-unknown.png'">
      </button>
    </header>
    <div class="nbs-nav" v-if="authentificationStore.isLogged">
      <nbs-card class="nbs-nav-card" route="/settings">
        <div class="nbs-card-header nbs-nav-cardHeader">
          <img alt="" src="@/assets/logo/fayardesign.png">
        </div>
        <div class="nbs-card-content nbs-nav-cardContent">
          <div role="heading" aria-level="1" class="nbs-card-title nbs-nav-cardTitle">Agence Fayardesign</div>
          BS-AFD23-001
        </div>
      </nbs-card>
      <nbs-menu :items="authentificationStore.isAdmin ? NAV_ADMIN : NAV_CONTACT"></nbs-menu>
    </div>
    <div :class="{ 'nbs-main-login' : !authentificationStore.isLogged }">
      <router-view :key="$route.fullPath" v-if="!isLoading" />
    </div>
  </div>
</template>

<style lang="scss">
@use 'sass-mq' as *;
@import './assets/styles/_mixins.scss';
@import './assets/styles/_variables.scss';

.nbs-main {
    display: grid;
    grid-gap: 0;
    grid-template-columns: rem(64px) rem(132px) auto;
    grid-auto-rows: minmax(rem(100px), auto);
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black-88;

    @include mq($from: wide) {
      grid-template-columns: rem(64px) rem(300px) auto;
    }

    &--login {
      display: block;
    }

    &-login {
      @include mq($from: desktop) {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 64% auto;
        height: 100%;
      }
    }
}

.nbs-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: rem(20px) 0;
  background: $black-88;

  &-logo {
    width: rem(24px);
    height: rem(24px);
    color: $white;
  }

  &-avatar {
    max-width: 100%;
    border-radius: 50%;
  }

  &-btn {
    border: 0;
    border-radius: 50%;
    padding: rem(2px);
    width: rem(28px);
    height: rem(28px);
    background: $white;
  }
}

.nbs-nav {
  border-right: rem(1px) solid $black-08;
  padding: rem(20px) $gutters;
  overflow-y: auto;
  overflow-x: hidden;

  &-card {
    display: flex;
    color: $black-64;

    &Header {
      @include mq($from: wide) {
        max-width: rem(32px);
        margin-right: $gutters;
      }
    }

    &Content {
      display: none;

      @include mq($from: wide) {
        display: block;
      }
    }

    &Title {
      text-transform: uppercase;
      color: $black-88;
    }
  }

  &-menu {
    padding: rem(20px) 0;
  }
}

.nbs-menu {
  &-item {
    &Label {
      display: none;

      @include mq($from: wide) {
        display: inline;
      }
    }
  }
}
</style>
