export const StatusData = {
  STATUS_CONFIRM_MAIL: 0,
  STATUS_ALERT: 1,
  STATUS_ALERT_RECENT: 2,
  STATUS_USER_ALERT: 3,
  STATUS_USER_ALERT_RECENT: 4,
  STATUS_COMPLIANT: 5,
  STATUS_COMPLIANT_RECENT: 6,
  STATUS_CERTIFIED: 7,
  STATUS_CERTIFIED_RECENT: 8,
  STATUS_LOCKED: 9,
  STATUS_DELETED: 10
}

export const getStatusIcon = (status) => {
  if (status === StatusData.STATUS_CONFIRM_MAIL) {
    return { name: 'waiting', color: 'orange' }
  } else if (status === StatusData.STATUS_ALERT) {
    return { name: 'warning', color: 'orange' }
  } else if (status === StatusData.STATUS_ALERT_RECENT) {
    return { name: 'warning-plain', color: 'orange' }
  } else if (status === StatusData.STATUS_COMPLIANT) {
    return { name: 'rounded-checked', color: 'green' }
  } else if (status === StatusData.STATUS_COMPLIANT_RECENT) {
    return { name: 'rounded-checked-plain', color: 'blue' }
  } else if (status === StatusData.STATUS_LOCKED) {
    return { name: 'locked', color: 'red' }
  } else if (status === StatusData.STATUS_DELETED) {
    return { name: 'bin', color: 'red' }
  } else if (status === StatusData.STATUS_CERTIFIED) {
    return { name: 'certified', color: 'green' }
  } else if (status === StatusData.STATUS_CERTIFIED_RECENT) {
    return { name: 'certified-plain', color: 'blue' }
  }
}
