export const FormFieldsProps = {
  id: String,
  label: String,
  name: String,
  default: {
    type: [String, Number, Object, Boolean],
    default: ''
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  showUpdate: {
    type: Boolean,
    default: true
  },
  reset: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  }
}

export const CardProps = {
  route: { type: String, required: false },
  type: {
    validator (value) {
      return ['', 'info', 'success', 'warning', 'error', 'updated', 'light', 'dark'].includes(value)
    }
  },
  toggle: {
    type: Boolean,
    default: false
  },
  toggleIcon: {
    type: String,
    default: ''
  },
  hasBorder: {
    type: Boolean,
    default: true
  },
  hasBorderHover: {
    type: Boolean,
    default: false
  }
}
