import { computed, ref } from 'vue'
import moment from 'moment'
import useAPI from '@/composables/useApi'

moment.locale('fr')

export const useAddress = () => {
  const API = useAPI()
  const address = ref({})
  const fetch = async (url) => {
    const response = await API.fetch(url, {
      method: 'get'
    })
    set(response.data)
    if (!address.value.phoneType) {
      address.value.phoneType = 1
    }
    if (!address.value.phoneAreaCode) {
      address.value.phoneAreaCode = '+33'
    }
    await fetchCountry()
    return address.value
  }

  const fetchCountry = async () => {
    if (address.value && address.value._links.country) {
      const response = await API.fetch(address.value._links.country.href, { method: 'get' })
      setCountry(response.data)
      return response
    }
  }

  const set = (data) => {
    address.value = data
  }

  const setCountry = (data) => {
    address.value.country = data
  }

  const reset = () => {
    set({})
  }

  const getAddress = computed(() => address.value)

  return {
    fetch,
    fetchCountry,
    set,
    reset,
    getAddress
  }
}

export default useAddress
