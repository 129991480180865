<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps(['icon', 'iconClass', 'color', 'folder'])

const colorClass = computed(() => {
  return props.color ? 'icon--' + props.color : ''
})
const folderPath = computed(() => {
  return props.folder ? props.folder + '/' : ''
})
const folderAnchor = computed(() => {
  return props.folder ? props.folder + '--' : ''
})
</script>

<template>
    <svg class="icon" :class="[ props.iconClass, colorClass ]">
        <use v-bind="{'xlink:href':'/sprite/' + folderPath + 'defs/svg/sprite.css.svg#src--assets--icons--' + folderAnchor + props.icon}"></use>
    </svg>
</template>

<style lang="scss">
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';

.icon {
  width: rem(16px);
  height: rem(16px);
  vertical-align: middle;

  &--light {
    color: $black-32;
  }

  &--red {
    color: $red
  }

  &--orange {
    color: $orange
  }

  &--green {
    color: $green
  }

  &--purple {
    color: $purple;
  }

  &--blue {
    color: $blue;
  }

  &--pink {
    color: $pink;
  }
}
</style>
