import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'reset-css/reset.css'
import './assets/styles/index.scss'

const pinia = createPinia()

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

createApp(App).use(pinia).use(router).mount('#app')
