export const dateDiff = (date1, date2) => {
  const diff = {}
  let tmp = date2 - date1

  tmp = Math.floor(tmp / 1000)
  diff.sec = tmp % 60
  tmp = Math.floor((tmp - diff.sec) / 60)
  diff.min = tmp % 60
  tmp = Math.floor((tmp - diff.min) / 60)
  diff.hour = tmp % 24
  tmp = Math.floor((tmp - diff.hour) / 24)
  diff.day = tmp

  return diff
}

export const datesCompare = (d1, d2) => {
  const date1 = new Date(d1).getTime()
  const date2 = new Date(d2).getTime()

  return date1 > date2
}

export default {
  dateDiff,
  datesCompare
}
