
export const useGroupedActions = () => {
  const actions = [
    {
      label: 'Exporter au format PDF',
      icon: 'share',
      onClick: () => false
    },
    {
      label: 'Supprimer',
      icon: 'bin',
      onClick: () => false
    }
  ]

  return actions
}
