<script setup>
import { defineProps } from 'vue'
import { useModalStore } from '@/store/modal'
import NbsIcon from '../NbsIcon.vue'

const modalStore = useModalStore()
defineProps(['leaveFunction'])
</script>

<template>
  <div class="nbs-warning-leaving">
    <nbs-icon icon="warning-triangle" class="nbs-warning-leaving-icon"/>
    <h1 class="h2">Attention !</h1>
    <p class="txt-medium">Vous-êtes sur le point de quitter cette page avant d’avoir enregistrer.</p>
    <p class="txt-light">Si vous confirmez votre choix, les informations saisies dans les formulaires seront perdues.</p>
    <button class="nbs-btn nbs-btn--secondary" @click="modalStore.close">Rester sur cette page</button>
    <button class="nbs-btn nbs-btn--warning" @click="leaveFunction">Quitter sans enregistrer</button>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';

h1 {
  margin-top: rem(16px);
}
.nbs-btn {
  width: 100%;

  &--secondary {
    margin: rem(24px) 0 rem(16px);
  }
}

.nbs-warning-leaving {
  padding: rem(32px);
  text-align: center;

  &-icon {
    width: rem(64px);
    height: rem(64px);
  }
}
</style>
