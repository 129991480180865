<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import { CardProps } from '@/props'
import NbsIcon from '@/components/NbsIcon.vue'

const props = defineProps({
  ...CardProps
})
const emit = defineEmits(['onToggle'])
const isToggled = ref(false)

const toggle = () => {
  isToggled.value = !isToggled.value
  emit('onToggle', isToggled.value)
}

const toggleClass = computed(() => {
  return props.toggle ? 'nbs-card--toggle' : ''
})

const noBorderClass = computed(() => {
  return !props.hasBorder ? 'nbs-card--noborder' : ''
})

const borderHoverClass = computed(() => {
  return props.hasBorderHover ? 'nbs-card--hover' : ''
})

const classList = computed(() => ['nbs-card--' + props.type, toggleClass.value, noBorderClass.value, borderHoverClass.value])
</script>

<template>
    <router-link v-if="props.route" :to="props.route" class="nbs-card" :class="classList">
      <slot></slot>
    </router-link>
    <div @click="toggle" class="nbs-card" :class="classList" v-else>
      <template v-if="$slots.toggle">
        <div class="nbs-card-headToggle">
          <slot></slot>
          <nbs-icon
            v-if="props.toggleIcon"
            class="nbs-card-headToggleIcon"
            :icon="props.toggleIcon"
            :class="{ 'nbs-card-headToggleIcon--opened': isToggled }"></nbs-icon>
        </div>
        <transition>
          <div class="nbs-card-contentToggle" v-if="props.toggle" v-show="isToggled" v-cloak>
            <slot name="toggle"></slot>
          </div>
        </transition>
      </template>
      <slot v-else></slot>
    </div>
</template>

<style lang="scss">
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';
.nbs-card {
  display: block;
  position: relative;
  border: 1px solid $black-08;
  border-radius: rem(12px);
  padding: rem(16px);
  background-color: $white;

  & + .nbs-card {
    margin-top: rem(8px);
  }

  &--hover {
    border: 2px solid transparent;
    transition: $transition-border;
  }

  &--noborder {
    border-color: transparent;
  }

  &--toggle {
    cursor: pointer;
  }

  &--light {
    background-color: $black-02;

    &.nbs-card--hover {
      &:hover {
        border-color: $black-88;
      }
    }
  }

  &--dark {
    background-color: $black-64;
    color: $white;
  }

  &--info {
    background-color: $blue-light;

    &.nbs-card--hover {
      &:hover {
        border-color: $blue;
      }
    }

    .nbs-card-title {
      color: $blue;
    }
  }

  &--error {
    background-color: $red-light;

    &.nbs-card--hover {
      &:hover {
        border-color: $red;
      }
    }

    .nbs-card-title {
      color: $red;
    }
  }

  &--warning {
    background-color: $orange-light;

    &.nbs-card--hover {
      &:hover {
        border-color: $orange;
      }
    }

    .nbs-card-title {
      color: $orange;
    }
  }

  &--success {
    background-color: $green-light;

    &.nbs-card--hover {
      &:hover {
        border-color: $green;
      }
    }

    .nbs-card-title {
      color: $green;
    }
  }

  &--updated {
    background-color: $purple-light;

    &.nbs-card--hover {
      &:hover {
        border-color: $purple;
      }
    }

    .nbs-card-title {
      color: $purple;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    margin-top: 0;
    font-size: rem(16px);
    font-weight: 600;
  }

  &-headToggle {
    position: relative;

    &Icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s linear;

      &--opened {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  &-content {
    font-weight: 400;

    &Toggle {
      display: flex;
      justify-content: space-between;
      padding-top: rem(16px);
      width: 100%;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    margin-top: rem(8px);
  }
}

a.nbs-card {
  text-decoration: none;
  color: $black-88;
  &:hover {
    box-shadow: 0px rem(2px) rem(24px) $black-16;
  }
}
</style>
