export const USER_STATE_WAITING = 0
export const USER_STATE_ENABLED = 1
export const USER_STATE_DISABLED = 2
export const USER_STATE_DELETED = 3

export const USER_STATE_OPTIONS = [
  { title: 'En attente', value: USER_STATE_WAITING },
  { title: 'Actif', value: USER_STATE_ENABLED },
  { title: 'Désactivé', value: USER_STATE_DISABLED },
  { title: 'Supprimé', value: USER_STATE_DELETED }
]
