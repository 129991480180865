export const useGroupedActions = () => {
  const actions = [
    {
      label: 'Rassembler dans un <strong>nouveau groupe</strong>',
      icon: 'move-new-folder',
      onClick: () => false
    },
    {
      label: '<strong>Déplacer</strong> vers un groupe existant',
      icon: 'move-existing-folder',
      onClick: () => false
    },
    {
      label: 'Placer dans la <strong>corbeille</strong>',
      icon: 'bin',
      onClick: () => false
    }
  ]

  return actions
}
