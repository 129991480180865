import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useEditForm } from '@/composables/useEditForm'
import { useProjectStore } from '@/store/project'
import { footerOptions } from '@/datas/projects/formatting'

export const useEditProjectForm = (id) => {
  const projectStore = useProjectStore()
  const projectCopy = ref({})
  const footerOptionsList = ref(footerOptions)
  const route = useRoute()
  const editForm = useEditForm()
  const isLoaded = ref(projectStore.isLoaded)

  const construct = async () => {
    editForm.init()
    projectCopy.value = Object.assign({}, projectStore.project)
    initAlias()
    if (!projectStore.project.id || projectStore.project.id.toString() !== id) {
      const response = await projectStore.fetch(id)
      isLoaded.value = projectStore.isLoaded
      projectCopy.value = Object.assign(projectCopy.value, projectStore.project)
      initAlias()
      return response
    }
  }

  const initAlias = (value = '') => {
    if (projectStore.project.alias && projectStore.project.alias.length === 0) {
      projectCopy.value.alias = [value]
    }
  }

  const addNewAlias = (value = '') => {
    projectCopy.value.alias.push(value)
  }

  const removeAlias = (index) => {
    projectCopy.value.alias = projectCopy.value.alias.reduce((acc, item, currentIndex) => {
      if (index !== currentIndex) {
        acc.push(item)
      }
      return acc
    }, [])
  }

  const onRemoveAlias = (params) => {
    removeAlias(params.index)
    editForm.onEditField(params)
  }

  const successMsg = ref(false)
  const setSuccessMsg = (qs) => {
    if (qs === 'OK') {
      successMsg.value = 'Vos modifications ont bien été enregistrées !'
    }
  }

  setSuccessMsg(route.query)
  watch(
    () => route.query,
    setSuccessMsg
  )

  const onSubmit = async () => {
    if (editForm.isEditProgressing.value) {
      editForm.isSubmitted.value = true
      const response = await projectStore.update(projectCopy.value)
      editForm.reset()
      setSuccessMsg('OK')
      return response
    }
  }

  return {
    ...editForm,
    isLoaded,
    successMsg,
    projectCopy,
    footerOptionsList,
    onSubmit,
    initAlias,
    addNewAlias,
    removeAlias,
    onRemoveAlias,
    construct
  }
}

export default useEditProjectForm
