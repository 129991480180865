export const FREE_COMPONENTS_DECLI = [
  { value: 0, title: '4px' },
  { value: 1, title: '8px' },
  { value: 2, title: '12px' },
  { value: 3, title: '16px' },
  { value: 4, title: '24px' },
  { value: 5, title: '32px' },
  { value: 6, title: '48px' },
  { value: 7, title: '56px' },
  { value: 8, title: '64px' }
]

export const PAGE_TYPES = [
  { title: 'Page de contenu « mère » › Rubrique', value: 0 },
  { title: 'Page de contenu « Fille » › Sous-rubrique', value: 1 },
  { title: 'Elément de menu › Accès à un sous-menu', value: 2 },
  { title: 'Lien direct › « Interne » ou « Sortant »', value: 3 }
]

export const PAGE_MODELS = [
  { label: 'Vierge', value: '0' },
  { label: 'Projet', value: '1' },
  { label: 'Catalogue', value: '2' }
]

export default {
  FREE_COMPONENTS_DECLI,
  PAGE_TYPES,
  PAGE_MODELS
}
