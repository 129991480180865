export const constants = {
  WEB_TYPE: 0,
  AGENCE_TYPE: 1,
  FORMATION_TYPE: 2
}

export const TypesData = [
  { label: 'Projet Web BS<sup>3</sup>', plural: 'Projets Web BS<sup>3</sup>', name: 'Site Web BS<sup>3</sup>', value: constants.WEB_TYPE },
  { label: 'Projet Agence', plural: 'Projets Agence', name: 'Agence', value: constants.AGENCE_TYPE },
  { label: 'Formation', plural: 'Formations', value: constants.FORMATION_TYPE }
]
