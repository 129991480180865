export const ROUTE_BASE = '/documents'
export const ROUTE_ADD = 'add'
export const ROUTE_EDIT = 'edit'
export const ROUTE_FOLDER_EDIT = 'edit-folder'

export default {
  ROUTE_BASE,
  ROUTE_ADD,
  ROUTE_EDIT,
  ROUTE_FOLDER_EDIT
}
