import { computed, ref } from 'vue'
import { useLeaveFormModalStore } from '@/store/leaveFormModal'

export const useEditForm = () => {
  const leaveFormModalStore = useLeaveFormModalStore()

  const isSubmitted = ref(false)

  const isEditProgressing = computed(() => {
    return leaveFormModalStore.isEditing
  })

  const numberFieldsText = computed(() => {
    return leaveFormModalStore.editCount > 0 ? `${leaveFormModalStore.editCount} champ${leaveFormModalStore.editCount > 1 ? 's' : ''}` : ''
  })

  const titleText = computed(() => {
    return leaveFormModalStore.editCount > 0 ? 'Vous avez modifié' : 'Vous n\'avez rien modifié'
  })

  const onEditField = ({ newValue, oldValue, initialValue }) => {
    isSubmitted.value = false
    if (newValue !== initialValue && oldValue === initialValue) {
      leaveFormModalStore.editCount++
    } else if (newValue === initialValue) {
      leaveFormModalStore.editCount--
    }
  }

  const init = () => {
    leaveFormModalStore.isEditForm = isEditProgressing
  }

  return {
    editCount: computed(() => leaveFormModalStore.editCount),
    isSubmitted,
    isEditProgressing,
    numberFieldsText,
    titleText,
    onEditField,
    handleLeaveFormModal: leaveFormModalStore.handleOpenModal,
    setChangeRouteUrl: leaveFormModalStore.setChangeRouteUrl,
    reset: leaveFormModalStore.reset,
    init
  }
}

export default useEditForm
