export const Filters = [
  {
    name: 'Vue d\'ensemble',
    code: 'global',
    options: [
      {
        title: 'Tous les contacts',
        value: 'all'
      },
      {
        title: 'Tous les groupes',
        value: 'all-groups'
      },
      {
        title: 'Toute la communauté',
        value: 'all-community'
      }
    ]
  },
  {
    name: 'Statut des contacts',
    code: 'contact-status',
    options: [
      {
        title: 'En attente',
        value: 'waiting'
      },
      {
        title: 'Alerte & action requise',
        value: 'alert'
      },
      {
        title: 'E-mail vérifié',
        value: 'verified'
      },
      {
        title: 'Compte certifié',
        value: 'certified'
      },
      {
        title: 'Contact verrouillé',
        value: 'locked'
      },
      {
        title: 'Dans la corbeille',
        value: 'bin'
      }
    ]
  }
]
