export const Filters = [
  {
    name: 'Vue d\'ensemble',
    code: 'global',
    options: [
      {
        title: 'Tous',
        value: 'all'
      },
      {
        title: 'Tous les fichiers',
        value: 'all-files'
      },
      {
        title: 'Tous les dossiers',
        value: 'all-folders'
      },
      {
        title: 'Tous les albums',
        value: 'all-albums'
      },
      {
        title: 'Favoris',
        value: 'fav'
      },
      {
        title: 'Corbeille',
        value: 'bin'
      }
    ]
  },
  {
    name: 'Type de fichiers',
    code: 'filetype',
    options: [
      {
        title: 'Images'
      },
      {
        title: 'Vidéos'
      },
      {
        title: 'Audios'
      },
      {
        title: 'Documents PDF'
      },
      {
        title: 'Applications'
      },
      {
        title: 'Archives'
      }
    ]
  },
  {
    name: 'Documents partagés',
    code: 'shared',
    options: [
      {
        title: 'Partagés avec les autres'
      },
      {
        title: 'Partagés avec moi'
      }
    ]
  }
]
