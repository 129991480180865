import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'
import { useStorage } from '@vueuse/core'
import { useAPI, useCreateForm, useFile, useAddress } from '@/composables'
import { useCustomersStore } from '@/store/customers'
import { TypesData } from '@/datas/customers/types'
import { CUSTOMER_STORAGE } from '@/datas/globals'

moment.locale('fr')

const customerDefault = {
  phoneType: 1,
  phoneAreaCode: '+33',
  address: null,
  headquarter: null,
  billing: null,
  referrals: null,
  logo: null,
  files: {
    kbis: null,
    cgv: null,
    rib: null,
    sepa: null,
    id: null
  },
  network: {},
  networks: [],
  _links: {}
}

export const useCustomerStore = defineStore('useCustomerStore', () => {
  const customersStore = useCustomersStore()
  const API = useAPI()

  const LogoFile = useFile('CUSTOMER_LOGO_FILE')
  const KbisFile = useFile('CUSTOMER_KBIS_FILE')
  const RibFile = useFile('CUSTOMER_RIB_FILE')
  const SepaFile = useFile('CUSTOMER_SEPA_FILE')
  const IdFile = useFile('CUSTOMER_ID_FILE')
  const CguFiles = ref([])
  const MainAddress = useAddress()
  const Headquarter = useAddress()
  const BillingAddress = useAddress()
  const isLoaded = ref(false)

  const creationFields = {
    designation: { value: 0 },
    name: { value: '', required: true, isValid: false },
    contactEmail: { value: '', required: false, isValid: false },
    contactFirstname: { value: '', required: false, conditionalRequired: true, isValid: false },
    contactLastname: { value: '', required: false, conditionalRequired: true, isValid: false },
    contactPhoneType: { value: '1', required: false, conditionalRequired: false, isValid: false },
    contactPhoneArea: { value: '+33', required: false, conditionalRequired: false, isValid: false },
    contactPhoneNumber: { value: '', required: false, conditionalRequired: false, isValid: false },
    existingContact: { value: '', required: false, isValid: false },
    addr1: { value: '', required: true, isValid: false },
    addr2: { value: '', required: false, isValid: true },
    zipcode: { value: '', required: true, isValid: false },
    city: { value: '', required: true, isValid: false },
    region: { value: '', required: false, isValid: true },
    country: { value: 75, required: false, isValid: true }
  }

  const customer = useStorage(CUSTOMER_STORAGE, customerDefault)
  const referrals = ref([])

  const createForm = useCreateForm(creationFields)

  const emptyFieldsNumbersContact = computed(() => {
    const fields = createForm.getCreateFields()
    return Object.entries(fields).reduce((acc, item) => {
      if (fields.contactEmail) {
        if (fields.contactEmail.isValid &&
          typeof item[1] === 'object' &&
          item[1].conditionalRequired &&
          item[1].value === '') {
          acc++
        }
      }
      return acc
    }, 0)
  })

  const emptyFieldsNumbers = computed(() => {
    return createForm.emptyFieldsNumbersDefault.value + emptyFieldsNumbersContact.value
  })

  const getType = computed(() => {
    const filter = TypesData.filter((item) => item.value === customer.value.type)
    return filter[0] || { label: '' }
  })

  const getFormattedCreationDate = computed(() => {
    return moment(customer.value.createdAt).format('LL')
  })

  const getFormattedUpdatedDate = computed(() => {
    return moment(customer.value.updatedAt).format('LL')
  })

  const getUpdatedInfo = computed(() => {
    return customer.value.updatedAt
      ? 'Modifié le <span class="txt-regular">' + getFormattedUpdatedDate.value + '</span> par <span class="txt-regular">Jonathan Jeune</span>'
      : 'Cette fiche n’a jamais été modifiée'
  })

  const getLegalForm = computed(() => {
    return customer.value.legalForm ? customer.value.legalForm.toString() : ''
  })

  const getPercentageCompletion = computed(() => {
    const values = Object.values(customer.value)
    const emptyFields = values.filter((value) => {
      return value && value !== ''
    })
    return Math.round(emptyFields.length / values.length * 100)
  })

  const getNetworks = computed(() => {
    return customer.value.network ? Object.entries(customer.value.network) : {}
  })

  const getResponsibleReferral = computed(() => {
    return referrals.value.filter((item) => item.responsible)[0]
  })

  const isOkPresentation = computed(() => {
    if ((customer.value._links && !customer.value._links.logo) || !customer.value._links) {
      return 'WARNING'
    } else {
      return customer.value._links && customer.value._links.logo && customer.value.name !== ''
    }
  })

  const isOkContactDetails = computed(() => {
    return MainAddress.getAddress &&
      MainAddress.getAddress.main !== null &&
      MainAddress.getAddress.zipCode !== null &&
      MainAddress.getAddress.city !== null &&
      customer.value.mail !== null &&
      customer.value.mail !== '' &&
      customer.value.phoneNumber !== null &&
      customer.value.phoneNumber !== ''
  })

  const isOkContactPeoples = computed(() => {
    return customer.value.referrals && customer.value.referrals.length > 0
  })

  const isOkLegals = computed(() => {
    return customer.value.businessName !== null && customer.value.businessName !== '' &&
    customer.value.commercialRegister !== null && customer.value.commercialRegister !== '' &&
      customer.value.corporateName !== null && customer.value.corporateName !== '' &&
      customer.value.capital !== null && customer.value.capital !== '' &&
      customer.value.commercialRegister !== null && customer.value.commercialRegister !== '' &&
      customer.value.legalActivity !== null && customer.value.legalActivity !== '' &&
      customer.value.legalForm !== null && customer.value.legalForm !== '' &&
      customer.value.nafCode !== null && customer.value.nafCode !== '' &&
      customer.value.siren !== null && customer.value.siren !== '' &&
      customer.value.siret !== null && customer.value.siret !== '' &&
      customer.value.tvaNumber !== null && customer.value.tvaNumber !== ''
  })

  const isMissingCGU = computed(() => CguFiles.value.length === 0)
  const isWaitingCGU = computed(() => CguFiles.value.length && CguFiles.value.filter((file) => file.isValidated).length === 0)

  const waitingRequiredDocs = computed(() => {
    let nb = 0
    if (IdFile.isMissing.value || IdFile.isRefused.value) {
      nb++
    }
    if (KbisFile.isMissing.value || KbisFile.isRefused.value) {
      nb++
    }
    if (isWaitingCGU.value) {
      nb += CguFiles.value.length
    }
    return nb
  })

  const waitingRecommandedDocs = computed(() => {
    let nb = 0
    if (RibFile.isMissing.value || RibFile.isRefused.value) {
      nb++
    }
    if (SepaFile.isMissing.value || SepaFile.isRefused.value) {
      nb++
    }
    return nb
  })

  const waitingActions = computed(() => {
    let nb = 0
    if (IdFile.isWaiting.value) {
      nb++
    }
    if (KbisFile.isWaiting.value) {
      nb++
    }
    if (RibFile.isWaiting.value) {
      nb++
    }
    if (SepaFile.isWaiting.value && !SepaFile.isValidated.value) {
      nb++
    }
    return nb
  })

  const isOkDocuments = computed(() => {
    return waitingRequiredDocs.value === 0 && waitingRecommandedDocs.value === 0 && waitingActions.value === 0
  })

  const isOkBillings = computed(() => {
    return customer.value.accountingName !== null && customer.value.accountingName !== '' &&
      customer.value.accoutingFirstName !== null && customer.value.accoutingFirstName !== '' &&
      customer.value.accountingLastName !== null && customer.value.accountingLastName !== '' &&
      customer.value.accountingEmail !== null && customer.value.accountingEmail !== '' &&
      customer.value.accountingPhoneNumber !== null && customer.value.accountingPhoneNumber !== ''
  })

  const isOkProjects = computed(() => {
    return false
  })

  const setCustomer = (value) => {
    customer.value = value
    localStorage.setItem(CUSTOMER_STORAGE, customer.value)
  }

  const setLinks = (value) => {
    customer.value._links = value
  }

  const fetchReferrals = async () => {
    referrals.value = []
    if (customer.value._links && customer.value._links.referrals) {
      customer.value._links.referrals.forEach(async (item) => {
        const response = await API.fetch(item.href, { method: 'get' })
        referrals.value.push(Object.assign({
          responsible: response.data.responsible
        }, response.data.user))
      })
    }
  }

  const fetchAddress = async () => {
    if (customer.value._links && customer.value._links.address) {
      customer.value.address = await MainAddress.fetch(customer.value._links.address.href)
      return customer.value.address
    }
  }

  const fetchHeadquarter = async () => {
    if (customer.value._links && customer.value._links.headquarter) {
      customer.value.headquarter = await Headquarter.fetch(customer.value._links.headquarter.href)
      return customer.value.headquarter
    }
  }

  const fetchBillingAddress = async () => {
    if (customer.value._links && customer.value._links.billing) {
      customer.value.billing = await BillingAddress.fetch(customer.value._links.billing.href)
      return customer.value.billing
    }
  }

  const fetchKbis = async () => {
    if (customer.value._links && customer.value._links.kbis) {
      return await KbisFile.fetch(customer.value._links.kbis.href)
    }
  }

  const fetchCGU = async () => {
    if (customer.value._links && customer.value._links.cgs) {
      CguFiles.value = []
      customer.value._links.cgs.forEach(async (item, index) => {
        const file = useFile('CUSTOMER_CGU_FILE_' + customer.value.id + '_' + index)
        CguFiles.value.push(file)
        await file.fetch(item.href)
      })
    } else {
      CguFiles.value.push(useFile('CUSTOMER_CGU_FILE_' + customer.value.id + '_O'))
    }
  }

  const fetchRib = async () => {
    if (customer.value._links && customer.value._links.rib) {
      return await RibFile.fetch(customer.value._links.rib.href)
    }
  }

  const fetchSepa = async () => {
    if (customer.value._links && customer.value._links.sepa) {
      return await SepaFile.fetch(customer.value._links.sepa.href)
    }
  }

  const fetchIdDoc = async () => {
    if (customer.value._links && customer.value._links.identity_document) {
      return await IdFile.fetch(customer.value._links.identity_document.href)
    }
  }

  const fetchLogo = async () => {
    if (typeof customer.value === 'object') {
      customer.value.logo = null
      LogoFile.reset()
      if (customer.value._links && customer.value._links.logo) {
        const response = await LogoFile.fetch(customer.value._links.logo.href)
        const url = process.env.VUE_APP_MEDIAS_URL + response.path
        customersStore.setLogo(url, customer.value.id)
        customer.value.logo = url
        localStorage.setItem(CUSTOMER_STORAGE, customer.value)
        return response
      }
    }
  }

  const deleteLogo = async () => {
    if (LogoFile.getId) {
      return await LogoFile.remove()
    }
  }

  const addContact = async ({ id, firstName, lastName, mail, phoneAreaCode, phoneNumber, isResponsible }) => {
    let data
    if (id) {
      data = {
        id,
        responsible: isResponsible
      }
    } else {
      data = {
        firstName,
        lastName,
        mail,
        phoneAreaCode,
        phoneNumber,
        responsible: isResponsible
      }
    }
    /* TODO */
    return data
  }

  const addNewCgu = () => {
    CguFiles.value.push(useFile('CUSTOMER_CGU_FILE_' + CguFiles.value.length))
  }

  const fetch = async (id) => {
    try {
      const response = await API.fetch('/customer/' + id, { method: 'get' })
      if (response) {
        setCustomer(response.data)
        fetchReferrals()
        fetchAddress()
        fetchHeadquarter()
        fetchBillingAddress()
        fetchKbis()
        fetchCGU()
        fetchRib()
        fetchSepa()
        fetchLogo()
        fetchIdDoc()
        isLoaded.value = true
      }
      return response
    } catch (e) {
      API.handleError(e)
    }
  }

  const create = async () => {
    if (emptyFieldsNumbers.value > 0) return false
    const fields = createForm.getCreateFields()
    const data = {
      name: fields.name.value,
      type: parseInt(fields.designation.value),
      address: {
        main: fields.addr1.value,
        additional: fields.addr2.value,
        zipCode: fields.zipcode.value,
        city: fields.city.value,
        country: { id: parseInt(fields.country.value) },
        area: fields.region.value
      }
    }
    if (fields.existingContact.value) {
      data.referrals = new Array({
        id: parseInt(fields.existingContact.value),
        responsible: true
      })
    } else if (fields.contactEmail.value !== '') {
      data.referrals = new Array({
        user: {
          lastName: fields.contactLastname.value,
          firstName: fields.contactFirstname.value,
          email: fields.contactEmail.value,
          phoneType: parseInt(fields.contactPhoneType.value),
          phoneAreaCode: fields.contactPhoneArea.value,
          phoneNumber: fields.contactPhoneNumber.value
        },
        responsible: true
      })
    }
    return await API.fetch('/customer', {
      method: 'post',
      data
    })
  }

  const prepareUpdateData = (obj) => {
    const data = {
      name: obj.name,
      type: obj.type,
      franchise: obj.franchise,
      slogan: obj.slogan,
      description: obj.description,
      mail: obj.mail,
      businessName: obj.businessName,
      corporateName: obj.corporateName,
      activity: obj.activity,
      legalActivity: obj.legalActivity,
      nafCode: obj.nafCode,
      legalForm: obj.legalForm,
      commercialRegister: obj.commercialRegister,
      siren: obj.siren,
      siret: obj.siret,
      tvaNumber: obj.tvaNumber,
      accountingName: obj.accountingName,
      accoutingFirstName: obj.accoutingFirstName,
      accountingLastName: obj.accountingLastName,
      accountingEmail: obj.accountingEmail,
      phoneType: obj.phones[0] ? parseInt(obj.phones[0].type) : null,
      phoneAreaCode: obj.phones[0] ? obj.phones[0].areaCode : null,
      phoneNumber: obj.phones[0] ? obj.phones[0].number : null,
      altPhoneType: obj.phones[1] ? parseInt(obj.phones[1].type) : null,
      altPhoneAreaCode: obj.phones[1] ? obj.phones[1].areaCode : null,
      altPhoneNumber: obj.phones[1] ? obj.phones[1].number : null,
      capital: parseInt(obj.capital || 0),
      sameAddress: obj.sameAddress,
      sameAddressBilling: obj.sameAddressBilling,
      network: obj.network
    }

    if (obj.address) {
      data.address = {
        id: obj.address.id,
        main: obj.address.main,
        additional: obj.address.additional,
        zipCode: obj.address.zipCode,
        city: obj.address.city,
        country: { id: obj.address.country.id }
      }
    }

    if (!obj.sameAddress) {
      data.headquarter = obj.headquarter
    }

    if (!obj.sameAddressBilling) {
      data.billing = obj.billing
    }

    return data
  }

  const update = async (obj) => {
    if (!obj) { return }
    const data = prepareUpdateData(obj)
    const response = await API.fetch('/customer/' + customer.value.id, {
      method: 'put',
      data
    })
    if (obj.name !== customer.value.name) {
      customersStore.setName(obj.name, customer.value.id)
    }
    customer.value = Object.assign({}, obj)
    return response
  }

  const reset = () => {
    customer.value = customerDefault
    isLoaded.value = false
    localStorage.removeItem(CUSTOMER_STORAGE)
    LogoFile.reset()
    KbisFile.reset()
    SepaFile.reset()
    RibFile.reset()
    IdFile.reset()
    CguFiles.value = []
    MainAddress.reset()
    Headquarter.reset()
    BillingAddress.reset()
  }

  return {
    ...createForm,
    emptyFieldsNumbers,
    getType,
    getFormattedCreationDate,
    getFormattedUpdatedDate,
    getUpdatedInfo,
    getLegalForm,
    getPercentageCompletion,
    getNetworks,
    getResponsibleReferral,
    isOkPresentation,
    isOkContactDetails,
    isOkContactPeoples,
    isOkLegals,
    isOkDocuments,
    isOkBillings,
    isOkProjects,
    isMissingCGU,
    isWaitingCGU,
    waitingActions,
    waitingRecommandedDocs,
    waitingRequiredDocs,
    setCustomer,
    setLinks,
    fetchReferrals,
    fetchAddress,
    fetchHeadquarter,
    fetchBillingAddress,
    fetchKbis,
    fetchCGU,
    fetchRib,
    fetchSepa,
    fetchIdDoc,
    fetchLogo,
    deleteLogo,
    addContact,
    addNewCgu,
    fetch,
    create,
    update,
    reset,
    customer,
    referrals,
    isLoaded,
    LogoFile,
    KbisFile,
    SepaFile,
    RibFile,
    IdFile,
    CguFiles
  }
})
