import { StatusData } from './status'
import { TypesData } from './types'

export const Filters = [
  {
    name: 'Vue d\'ensemble',
    code: 'global',
    options: [
      {
        title: 'Tous',
        value: 'all'
      },
      {
        title: 'Projets ajoutés récemment',
        value: 'recently-added'
      },
      {
        title: 'Projets édités depuis peu',
        value: 'recently-edited'
      },
      {
        title: 'Projets archivés ou annulés',
        value: 'archived'
      }
    ]
  },
  {
    name: 'Etat d\'avancement',
    code: 'status',
    options: StatusData.map(item => {
      return {
        title: item.plural || item.label,
        value: item.value
      }
    })
  },
  {
    name: 'Technique & configuration',
    code: 'tech',
    options: [
      {
        title: 'Mise à jour disponible',
        value: 'available-update'
      },
      {
        title: 'Déploiement en attente',
        value: 'waiting-deploy'
      }
    ]
  },
  {
    name: 'Type de prestation',
    code: 'type',
    options: TypesData.map(item => {
      return {
        title: item.plural || item.label,
        value: item.value
      }
    })
  }
]
