export const ROUTE_BASE = '/projects'
export const ROUTE_ADD = 'add'
export const ROUTE_EDIT = 'edit'
export const ROUTE_EDIT_PAGE = ROUTE_EDIT + '/page'
export const ROUTE_DESC = 'description'
export const ROUTE_FORMATTING = 'formatting'
export const ROUTE_TECH = 'technical'
export const ROUTE_STRUCTURE = 'structure'
export const ROUTE_CUSTOM = 'customizing'
export const ROUTE_FUNCTIONNAL = 'functionnal'
export const ROUTE_COMMERCIAL = 'commercial'

export default {
  ROUTE_BASE,
  ROUTE_ADD,
  ROUTE_EDIT,
  ROUTE_EDIT_PAGE,
  ROUTE_DESC,
  ROUTE_FORMATTING,
  ROUTE_TECH,
  ROUTE_STRUCTURE,
  ROUTE_CUSTOM,
  ROUTE_FUNCTIONNAL,
  ROUTE_COMMERCIAL
}
