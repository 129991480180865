import { createRouter, createWebHistory } from 'vue-router'
import * as CustomersRoutes from '@/datas/customers/routes'
import * as ProjectsRoutes from '@/datas/projects/routes'
import * as CommunityRoutes from '@/datas/community/routes'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardView.vue'),
    beforeEnter: () => {
      localStorage.removeItem('NAZ_CUSTOMER')
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/LoginView.vue'),
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            name: 'login',
            component: () => import('@/views/login/LoginFormView.vue')
          },
          {
            path: 'error',
            component: () => import('@/views/login/LoginFormErrorView.vue')
          }
        ]
      },
      {
        path: 'forgot-password',
        component: () => import('@/views/login/LoginForgotPwdView.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/login/LoginForgotPwdFormView.vue')
          },
          {
            path: 'error',
            component: () => import('@/views/login/LoginForgotPwdErrorView.vue')
          },
          {
            path: 'confirm',
            component: () => import('@/views/login/LoginForgotPwdConfirmView.vue')
          },
          {
            path: 'confirm-error',
            component: () => import('@/views/login/LoginForgotPwdConfirmErrorView.vue')
          }
        ]
      },
      {
        path: 'reset-password',
        component: () => import('@/views/login/LoginResetPwdView.vue')
      },
      {
        path: 'secure-account',
        component: () => import('@/views/login/LoginSecureAccountView.vue')
      },
      {
        path: 'account-secured',
        component: () => import('@/views/login/LoginAccountSecuredView.vue')
      }
    ]
  },
  {
    path: CustomersRoutes.ROUTE_BASE,
    children: [
      {
        path: '',
        component: () => import('@/views/customer/CustomersView.vue')
      },
      {
        path: CustomersRoutes.ROUTE_ADD,
        component: () => import('@/views/customer/CustomersAddView.vue')
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: () => import('@/views/customer/CustomerSingleView.vue')
          },
          {
            path: CustomersRoutes.ROUTE_EDIT,
            component: () => import('@/views/customer/CustomersEditView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/customer/CustomersEditPresentationView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_CONTACT,
                component: () => import('@/views/customer/CustomersEditContactDetailsView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_PEOPLES,
                component: () => import('@/views/customer/CustomersEditContactPeoplesView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_LEGALS,
                component: () => import('@/views/customer/CustomersEditLegalsInfosView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_DOCUMENTS,
                component: () => import('@/views/customer/CustomersEditDocumentsView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_BILLINGS,
                component: () => import('@/views/customer/CustomersEditBillingsView.vue')
              },
              {
                path: CustomersRoutes.ROUTE_PROJECTS,
                component: () => import('@/views/customer/CustomersEditProjectsView.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: ProjectsRoutes.ROUTE_BASE,
    children: [
      {
        path: '',
        component: () => import('@/views/projects/ProjectsView.vue'),
        beforeEnter: () => {
          localStorage.removeItem('NAZ_CUSTOMER')
        }
      },
      {
        path: ProjectsRoutes.ROUTE_ADD,
        component: () => import('@/views/projects/ProjectsAddView.vue')
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: () => import('@/views/projects/ProjectSingleView.vue')
          },
          {
            path: ProjectsRoutes.ROUTE_EDIT,
            component: () => import('@/views/projects/ProjectEditView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/projects/ProjectEditManageView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_DESC,
                component: () => import('@/views/projects/ProjectEditDescriptionView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_FORMATTING,
                component: () => import('@/views/projects/ProjectEditFormattingView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_TECH,
                component: () => import('@/views/projects/ProjectEditTechnicalView.vue')
              },
              {
                path: ProjectsRoutes.ROUTE_STRUCTURE,
                component: () => import('@/views/projects/ProjectEditStructureView.vue')
              }
            ]
          },
          {
            path: ProjectsRoutes.ROUTE_EDIT_PAGE + '/:pageid',
            component: () => import('@/views/projects/ProjectEditPageView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/documents',
    children: [
      {
        path: '',
        component: () => import('@/views/documents/DocumentsView.vue')
      },
      {
        path: ':id',
        component: () => import('@/views/documents/DocumentSingleView.vue')
      },
      {
        path: 'folder/:id',
        children: [
          {
            path: '',
            component: () => import('@/views/documents/DocumentFolderView.vue')
          },
          {
            path: 'document/:docid',
            component: () => import('@/views/documents/DocumentSingleView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/providers',
    children: [
      {
        path: '',
        component: () => import('@/views/providers/ProvidersView.vue')
      }
    ]
  },
  {
    path: CommunityRoutes.ROUTE_BASE,
    children: [
      {
        path: '',
        component: () => import('@/views/community/CommunityView.vue')
      },
      {
        path: CommunityRoutes.ROUTE_ADD_CONTACT,
        component: () => import('@/views/community/CommunityAddContactView.vue')
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: () => import('@/views/community/CommunitySingleContactView.vue')
          },
          {
            path: CommunityRoutes.ROUTE_EDIT,
            component: () => import('@/views/community/CommunityEditContactView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/community/CommunityEditContactAboutView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_PRESENTATION,
                component: () => import('@/views/community/CommunityEditContactPresentationView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_CONTACT,
                component: () => import('@/views/community/CommunityEditContactInfosView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_ROLES,
                component: () => import('@/views/community/CommunityEditContactRolesView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_SERVICES,
                component: () => import('@/views/community/CommunityEditContactServicesView.vue')
              },
              {
                path: CommunityRoutes.ROUTE_SECURITY,
                component: () => import('@/views/community/CommunityEditContactSecurityView.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/communication',
    children: [
      {
        path: '',
        component: () => import('@/views/communication/CommunicationView.vue')
      }
    ]
  },
  {
    path: '/products',
    children: [
      {
        path: '',
        component: () => import('@/views/products/ProductsView.vue')
      }
    ]
  },
  {
    path: '/comptability',
    children: [
      {
        path: '',
        component: () => import('@/views/comptability/ComptabilityView.vue')
      }
    ]
  },
  {
    path: '/stats',
    children: [
      {
        path: '',
        component: () => import('@/views/stats/StatsView.vue')
      }
    ]
  },
  {
    path: '/settings',
    children: [
      {
        path: '',
        component: () => import('@/views/settings/SettingsView.vue')
      }
    ]
  },
  {
    path: '/support',
    children: [
      {
        path: '',
        component: () => import('@/views/support/SupportView.vue')
      }
    ]
  },
  {
    path: '/help',
    children: [
      {
        path: '',
        component: () => import('@/views/help/HelpView.vue')
      }
    ]
  },
  {
    path: '/planning',
    children: [
      {
        path: '',
        component: () => import('@/views/planning/PlanningView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
