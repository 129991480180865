import useAPI from '@/composables/useApi'

export const useUploadFile = () => {
  const API = useAPI()
  const upload = (fmData) => {
    return API.fetch('/file', {
      method: 'post',
      uploadFile: true,
      data: fmData
    })
  }

  return { upload }
}

export default useUploadFile
