import { defineStore } from 'pinia'
import { ref, shallowRef } from 'vue'

export const useModalStore = defineStore('useModalStore', () => {
  const isOpened = ref(false)
  const htmlClasses = ref('')
  const hasCloseButton = ref(true)
  const isFixedHeight = ref(false)
  const hasCloseOnOverlay = ref(true)
  const height = ref(null)
  const modalState = ref({ component: null, props: {}, callbacks: {} })
  const formData = ref({})

  const open = ({ props, component, callbacks, htmlClass, closeButton = true, closeOverlay = true, fixedHeight, formValues }) => {
    if (typeof component === 'object') {
      isOpened.value = true
      htmlClasses.value = htmlClass
      hasCloseButton.value = closeButton
      hasCloseOnOverlay.value = closeOverlay
      isFixedHeight.value = fixedHeight
      formData.value = formValues
      modalState.value.component = shallowRef(component)
      modalState.value.props = props || {}
      Object.assign(modalState.value.callbacks, callbacks)
    }
  }

  const close = (callback) => {
    if (typeof modalState.value.callbacks.beforeClose === 'function') {
      modalState.value.callbacks.beforeClose.call()
    }
    modalState.value = { component: null, props: {}, callbacks: {} }
    isOpened.value = false
    htmlClasses.value = ''
    if (typeof callback === 'function') {
      callback.call()
    }
  }

  return {
    isOpened,
    htmlClasses,
    hasCloseButton,
    hasCloseOnOverlay,
    isFixedHeight,
    modalState,
    height,
    formData,
    open,
    close
  }
})
