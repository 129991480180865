export const useGroupedActions = () => {
  const actions = [
    {
      label: 'Rassembler dans un <strong>nouveau dossier</strong>',
      icon: 'move-new-folder',
      onClick: () => false
    },
    {
      label: '<strong>Déplacer</strong> vers un dossier existant',
      icon: 'move-existing-folder',
      onClick: () => false
    },
    {
      label: 'Définir un <strong>nouveau partage</strong>',
      icon: 'new-share',
      onClick: () => false
    },
    {
      label: '<strong>Étendre le partage</strong> existant',
      icon: 'extend-share',
      onClick: () => false
    },
    {
      label: '<strong>Télécharger</strong> sur ma machine',
      icon: 'download',
      onClick: () => false
    },
    {
      label: 'Placer dans la <strong>corbeille</strong>',
      icon: 'bin',
      onClick: () => false
    }
  ]

  return actions
}
