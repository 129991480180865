export const constants = {
  STATUS_DRAFT: 0,
  STATUS_START: 1,
  STATUS_BRIEFING: 2,
  STATUS_PROTO: 3,
  STATUS_DEV: 4,
  STATUS_PROD: 5,
  STATUS_ENDED: 6
}

export const StatusData = [
  { label: 'Brouillon', plural: 'Brouillons', value: constants.STATUS_DRAFT, desc: 'En cours de configuration & de quotation' },
  { label: 'Briefing', value: constants.STATUS_BRIEFING },
  { label: 'Prototype', plural: 'Prototypes', value: constants.STATUS_PROTO, desc: 'Le projet est présenté au client dans une forme fonctionnelle.' },
  { label: 'Développement', value: constants.STATUS_DEV, desc: 'Le projet est déployé et le client peut l\'administrer.' },
  { label: 'En production', value: constants.STATUS_PROD, desc: 'Le projet est terminé et livré.' },
  { label: 'Archivé ou annulé', plural: 'Archivés ou annulés', value: constants.STATUS_ENDED }
]

export const getStatusIcon = (status) => {
  if (status === constants.STATUS_BRIEFING) {
    return { name: 'warning', color: 'purple', text: 'brief' }
  } else if (status === constants.STATUS_START) {
    return { name: 'rounded-maintenance', color: 'blue', text: 'start' }
  } else if (status === constants.STATUS_PROTO) {
    return { name: 'rounded-checked-plain', color: 'pink', text: 'proto' }
  } else if (status === constants.STATUS_DEV) {
    return { name: 'waiting', color: 'orange', text: 'dev' }
  } else if (status === constants.STATUS_PROD) {
    return { name: 'rounded-checked-plain', color: 'green', text: 'prod' }
  } else if (status === constants.STATUS_ENDED) {
    return { name: 'rounded-checked', color: 'green', text: 'ended' }
  } else {
    return { name: 'warning', color: 'grey', text: 'draft' }
  }
}
