export const ROUTE_BASE = '/community'
export const ROUTE_ADD_CONTACT = 'add'
export const ROUTE_ADD_GROUP = 'add-group'
export const ROUTE_EDIT = 'edit'
export const ROUTE_PRESENTATION = 'presentation'
export const ROUTE_CONTACT = 'contact-infos'
export const ROUTE_ROLES = 'roles'
export const ROUTE_SERVICES = 'services'
export const ROUTE_SECURITY = 'security'

export default {
  ROUTE_BASE,
  ROUTE_ADD_CONTACT,
  ROUTE_ADD_GROUP,
  ROUTE_PRESENTATION,
  ROUTE_CONTACT,
  ROUTE_ROLES,
  ROUTE_SERVICES,
  ROUTE_SECURITY
}
