export const TPL_FILTERS = [
  {
    cat: 'Nombre de colonnes',
    filters: [
      {
        name: 'Une colonne',
        value: '1c',
        checked: false
      },
      {
        name: 'Deux colonnes',
        value: '2c',
        checked: false
      },
      {
        name: 'Trois colonnes',
        value: '3c',
        checked: false
      }
    ]
  },
  {
    cat: 'Nombre d\'ensembles',
    filters: [
      {
        name: 'Un ensemble',
        value: '1e',
        checked: false
      },
      {
        name: 'Deux ensembles',
        value: '2e',
        checked: false
      },
      {
        name: 'Trois ensembles',
        value: '3e',
        checked: false
      },
      {
        name: 'Quatre & plus',
        value: '4e',
        checked: false
      }
    ]
  },
  {
    cat: 'Nombre de zones',
    filters: [
      {
        name: 'Une zone',
        value: '1z',
        checked: false
      },
      {
        name: 'Deux zones',
        value: '2z',
        checked: false
      },
      {
        name: 'Trois zones',
        value: '3z',
        checked: false
      },
      {
        name: 'Quatre zones & plus',
        value: '4z',
        checked: false
      }
    ]
  }
]
