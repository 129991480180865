export const ROUTE_BASE = '/customers'
export const ROUTE_ADD = 'add'
export const ROUTE_EDIT = 'edit'
export const ROUTE_CONTACT = 'contact-details'
export const ROUTE_PEOPLES = 'contact-peoples'
export const ROUTE_LEGALS = 'legals'
export const ROUTE_DOCUMENTS = 'documents'
export const ROUTE_BILLINGS = 'billings'
export const ROUTE_PROJECTS = 'projects'

export default {
  ROUTE_BASE,
  ROUTE_ADD,
  ROUTE_EDIT,
  ROUTE_CONTACT,
  ROUTE_PEOPLES,
  ROUTE_LEGALS,
  ROUTE_DOCUMENTS,
  ROUTE_BILLINGS,
  ROUTE_PROJECTS
}
